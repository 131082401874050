import pkceChallenge from "pkce-challenge";

const environment = process.env.REACT_APP_CURRENT_ENVIRONMENT;

const CLIENT_ID_MX = process.env.REACT_APP_SALESFORCE_CLIENT_ID_MX as string;
const CLIENT_ID_CO = process.env.REACT_APP_SALESFORCE_CLIENT_ID_CO as string;
const CLIENT_ID_PE = process.env.REACT_APP_SALESFORCE_CLIENT_ID_PE as string;
const CLIENT_ID_AR = process.env.REACT_APP_SALESFORCE_CLIENT_ID_AR as string;

const CLIENT_SECRET_MX = process.env
  .REACT_APP_SALESFORCE_CLIENT_SECRET_MX as string;
const CLIENT_SECRET_CO = process.env
  .REACT_APP_SALESFORCE_CLIENT_SECRET_CO as string;
const CLIENT_SECRET_PE = process.env
  .REACT_APP_SALESFORCE_CLIENT_SECRET_PE as string;
const CLIENT_SECRET_AR = process.env
  .REACT_APP_SALESFORCE_CLIENT_SECRET_AR as string;

const BUSINESS_CLIENT_MX = process.env.REACT_APP_BS_MX;
const BUSINESS_CLIENT_CO = process.env.REACT_APP_BS_CO;
const BUSINESS_CLIENT_PE = process.env.REACT_APP_BS_PE;
const BUSINESS_CLIENT_AR = process.env.REACT_APP_BS_AR;

export const REDIRECT_URI = process.env
  .REACT_APP_SALESFORCE_REDIRECT_URI as string;

const AUTH_URL = process.env.REACT_APP_SALESFORCE_AUTH_URL;
const SCOPE = "openid profile email";

export const getSalesforceRegion = (businessClientId: string) => {
  switch (businessClientId) {
    case BUSINESS_CLIENT_MX:
      return { clientId: CLIENT_ID_MX, region: "mx", secret: CLIENT_SECRET_MX };
    case BUSINESS_CLIENT_CO:
      return { clientId: CLIENT_ID_CO, region: "co", secret: CLIENT_SECRET_CO };
    case BUSINESS_CLIENT_PE:
      return { clientId: CLIENT_ID_PE, region: "pe", secret: CLIENT_SECRET_PE };
    case BUSINESS_CLIENT_AR:
      return { clientId: CLIENT_ID_AR, region: "ar", secret: CLIENT_SECRET_AR };
    default:
      return { clientId: CLIENT_ID_MX, region: "mx", secret: CLIENT_SECRET_MX };
  }
};

export const getSalesforceAuthUrl = async (businessClientId: string = "mx") => {
  const pkce = await pkceChallenge();
  sessionStorage.removeItem("pkce_verifier");
  sessionStorage.setItem("pkce_verifier", pkce.code_verifier);

  const { clientId, region } = getSalesforceRegion(businessClientId);

  if (environment === "Preproduction") {
    return `${AUTH_URL}/${region}/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      `${REDIRECT_URI}/${businessClientId}` ?? ""
    )}&scope=${encodeURIComponent(SCOPE)}&code_challenge=${
      pkce.code_challenge
    }&code_challenge_method=S256`;
  }

  return `${AUTH_URL}.${region}/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
    `${REDIRECT_URI}/${businessClientId}` ?? ""
  )}&scope=${encodeURIComponent(SCOPE)}&code_challenge=${
    pkce.code_challenge
  }&code_challenge_method=S256`;
};
