import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Icon } from "@iconify-icon/react";
import { useTranslation } from "react-i18next";

import stylesheet from "./SignUpExpress.module.scss";
import Input, { InputStyles } from "../Input/Input";
import Button, { ButtonStyle } from "../Button/Button";
import { CountryCode, getPhoneCodes } from "../../services/utils";
import { ArrowDownFill, FiraLogo } from "../FiraIcons";
import { FiraLoading } from "../FiraIcons/FiraLoading";
import {
  checkSignUpExpressStatus,
  getSalesforceUserInfo,
  signUpExpress,
} from "../../services/auth";
import { AlertType, usePopupAlert } from "../../providers/AlertProvider";
import {
  getSalesforceAuthUrl,
  getSalesforceRegion,
  REDIRECT_URI,
} from "../../utils/saleforceAuthUtils";
import { getQueryParams } from "../../utils/utils";
import MKLogo from "../../assets/img/mk-logo.png";

const MAX_RETRY = 2;

interface Form {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  phoneNumber: string;
}
enum WrapperState {
  INIT,
  LOADING,
  SUCCESS,
  FAILED,
  SALESFORCE_FAILED,
  PICK_COUNTRY,
  SIGN_UP_DISABLED,
}

const SignUpExpress: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [form, setForm] = React.useState<Form>({
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    phoneNumber: "",
  });
  const [phoneCode, setPhoneCode] = React.useState<CountryCode>();
  const [state, setState] = React.useState<WrapperState>(WrapperState.LOADING);
  const [result, setResult] = React.useState("");
  const showAlert = usePopupAlert();
  const { id } = useParams();
  const [country, setCountry] = React.useState<string>();
  const [codes, setCodes] = React.useState<CountryCode[]>([]);
  const currentRetries = parseInt(sessionStorage.getItem("RETRIES") ?? "0");

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value.trim().replaceAll(" ", "") });
  };

  const phoneSelectHandler = (code: CountryCode) => {
    setPhoneCode(code);
  };

  const submitHandler = async () => {
    try {
      setState(WrapperState.LOADING);
      const parseData = {
        businessClientId: id,
        firstName: form.firstName,
        lastName: form.lastName,
        userEmail: form.email,
        username: form.username,
        landingName: "",
        productLabel: null,
        phoneNumber: {
          country: phoneCode?.countryCode,
          operator: phoneCode?.dialCode,
          number: form.phoneNumber,
          type: "MOBILE",
        },
        uniqueExternalCode: "",
        role: null,
      };
      const response = await signUpExpress(parseData);
      setState(WrapperState.SUCCESS);
      setResult(response.message);
    } catch (error) {
      setState(WrapperState.FAILED);
      showAlert(AlertType.error, JSON.stringify(error));
    }
  };

  const salesforceLogin = async () => {
    sessionStorage.removeItem("pkce_verifier");
    window.location.href = location.pathname;
  };

  const handleURLCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        `${process.env.REACT_APP_BASE_URL_LANDING}${result}`
      );
      showAlert(AlertType.success, t("alerts.url_copy"));
    } catch (error) {
      showAlert(AlertType.error, JSON.stringify(error));
    }
  };

  const handleCountrySelect = (code: string) => {
    setCountry(code);
  };

  const handleSalesforceRedirect = async () => {
    console.log(country);
    // window.location.href = await getSalesforceAuthUrl();
  };

  const renderSuccess = () => {
    return (
      <div className={stylesheet.SuccessWrapper}>
        <span>{`${t("auth.express.success_title")}, ${form.firstName}`}</span>
        <span>{t("auth.express.success_description")}</span>
        <a
          href={`${process.env.REACT_APP_BASE_URL_LANDING}${result}`}
          target="_blank"
          className={stylesheet.URL}
        >
          {`${process.env.REACT_APP_BASE_URL_LANDING}`}
          <span>{result}</span>
        </a>
        <Button
          buttonStyle={ButtonStyle.mk}
          height="48px"
          clickHandler={handleURLCopy}
        >
          {t("buttons.copy_url")}
        </Button>
      </div>
    );
  };

  const renderFailed = () => {
    return (
      <div className={stylesheet.FailedWrapper}>
        <span>{t("auth.express.failed_description")}</span>
        <Button background="#F16063" height="40px" clickHandler={submitHandler}>
          <div className={stylesheet.InnerButton}>
            <Icon icon="uiw:reload" />
            <span>{t("buttons.try_again")}</span>
          </div>
        </Button>
      </div>
    );
  };

  const renderSalesforceFailed = () => {
    return (
      <div className={stylesheet.FailedWrapper}>
        <span>{t("auth.express.failed_description")}</span>
        <Button
          background="#F16063"
          height="40px"
          clickHandler={salesforceLogin}
        >
          <div className={stylesheet.InnerButton}>
            <Icon icon="uiw:reload" />
            <span>{t("buttons.try_again")}</span>
          </div>
        </Button>
      </div>
    );
  };

  const renderCountryPicker = () => {
    return (
      <div className={stylesheet.FailedWrapper}>
        <Button height="40px" clickHandler={handleSalesforceRedirect}>
          <div className={stylesheet.InnerButton}>
            <span>{"continuar"}</span>
          </div>
        </Button>
      </div>
    );
  };

  const renderSignUpDisabled = () => {
    return (
      <div className={stylesheet.FailedWrapper}>
        <div className={stylesheet.SignUpDisabled}>
          <span className={stylesheet.title}>
            {t("auth.express.disabled.title")}
          </span>
          <span className={stylesheet.message}>
            {t("auth.express.disabled.message")}
          </span>
          <span className={stylesheet.message}>
            {t("auth.express.disabled.closing")}
          </span>
        </div>
      </div>
    );
  };

  const renderWrapper = () => {
    switch (state) {
      case WrapperState.INIT:
        return (
          <SignUpForm
            form={form}
            phoneCode={phoneCode}
            codes={codes}
            onPhoneCodeChange={phoneSelectHandler}
            onInputChange={inputChangeHandler}
            onSubmit={submitHandler}
          />
        );
      case WrapperState.LOADING:
        return (
          <img
            className={stylesheet.Pulsing}
            src={MKLogo}
            style={{ height: 73 }}
            alt="Fira Live / Mary Kay"
          />
        );
      case WrapperState.SUCCESS:
        return renderSuccess();
      case WrapperState.FAILED:
        return renderFailed();
      case WrapperState.SALESFORCE_FAILED:
        return renderSalesforceFailed();
      case WrapperState.PICK_COUNTRY:
        return renderCountryPicker();
      case WrapperState.SIGN_UP_DISABLED:
        return renderSignUpDisabled();
    }
  };

  React.useEffect(() => {
    const initForm = async () => {
      const status = await checkSignUpExpressStatus(id ?? "");

      if (status) {
        const queryParams = getQueryParams(location.search);

        const code = queryParams["code"];
        const verifier = sessionStorage.getItem("pkce_verifier");
        if (code && verifier && id) {
          try {
            const { region } = getSalesforceRegion(id);
            const { email, firstName, lastName, username } =
              await getSalesforceUserInfo(code, verifier, region, id);

            setForm((prevState) => ({
              ...prevState,
              email,
              firstName,
              lastName,
              username,
            }));
            sessionStorage.setItem("RETRIES", "0");
            setState(WrapperState.INIT);
          } catch (error) {
            if (currentRetries < MAX_RETRY) {
              const retry = currentRetries + 1;
              sessionStorage.setItem("RETRIES", `${retry}`);
              salesforceLogin();
              return;
            }
            sessionStorage.setItem("RETRIES", "0");
            setState(WrapperState.SALESFORCE_FAILED);
          }
        } else {
          window.location.href = await getSalesforceAuthUrl(id);
        }
      } else {
        setState(WrapperState.SIGN_UP_DISABLED);
      }
    };

    initForm();

    const getCodes = async () => {
      try {
        const response = await getPhoneCodes();
        setCodes(response);
        const { region } = getSalesforceRegion(id ?? "");

        const code = response.find((c) =>
          c.countryCode.toLowerCase().includes(region)
        );
        setPhoneCode(code ?? response[0]);
      } catch (error) {
        // TODO: show error
        console.log(error);
      }
    };

    getCodes();
  }, []);

  return (
    <div className={stylesheet.Wrapper}>
      <Header />
      <div className={stylesheet.InnerWrapper}>{renderWrapper()}</div>
    </div>
  );
};

interface FormProps {
  form: Form;
  phoneCode?: CountryCode;
  codes: CountryCode[];
  onPhoneCodeChange(code: CountryCode): void;
  onInputChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onSubmit(): void;
}
const SignUpForm: React.FC<FormProps> = ({
  form,
  phoneCode,
  codes,
  onPhoneCodeChange,
  onInputChange,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const pattern = /^\d{1,}$/;
  const handlePhoneCodeSelect = (code: CountryCode) => {
    onPhoneCodeChange(code);
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <form style={FormWrapper} onSubmit={submitHandler}>
      <span style={Title}>{t("auth.express.title")}</span>
      <div style={InputWrapper}>
        <div className={stylesheet.LabelWrapper}>
          <label className={stylesheet.Label} htmlFor="firstName">
            {t("forms.name_label")}
          </label>
        </div>

        <Input
          inputStyle={InputStyles.mk}
          inputName="firstName"
          value={form.firstName}
          onChange={onInputChange}
          width="100%"
          height="40px"
        />
      </div>
      <div style={InputWrapper}>
        <div className={stylesheet.LabelWrapper}>
          <label className={stylesheet.Label} htmlFor="lastName">
            {t("forms.lastname_label")}
          </label>
        </div>

        <Input
          inputStyle={InputStyles.mk}
          inputName="lastName"
          value={form.lastName}
          onChange={onInputChange}
          width="100%"
          height="40px"
        />
      </div>
      <div style={InputWrapper}>
        <div className={stylesheet.LabelWrapper}>
          <label className={stylesheet.Label} htmlFor="username">
            {t("forms.user_label")}
          </label>
        </div>

        <Input
          inputStyle={InputStyles.mk}
          inputName="username"
          value={form.username}
          isDisabled
          onChange={onInputChange}
          width="100%"
          height="40px"
        />
      </div>
      <div style={InputWrapper}>
        <div className={stylesheet.LabelWrapper}>
          <label className={stylesheet.Label} htmlFor="email">
            {t("forms.email_label")}
          </label>
        </div>

        <Input
          inputStyle={InputStyles.mk}
          inputName="email"
          value={form.email}
          onChange={onInputChange}
          isDisabled={true}
          width="100%"
          height="40px"
        />
      </div>
      <div style={InputWrapper}>
        <div className={stylesheet.LabelWrapper}>
          <label className={stylesheet.Label} htmlFor="phoneNumber">
            {t("forms.whatsapp_label")}
          </label>
        </div>

        <div style={InnerInputWrapper}>
          <PhoneCodeSelect
            phoneCode={phoneCode}
            codes={codes}
            onPhoneCodeSelect={handlePhoneCodeSelect}
          />
          <Input
            inputStyle={InputStyles.mk}
            inputName="phoneNumber"
            value={form.phoneNumber}
            onChange={onInputChange}
            width="100%"
            height="40px"
            type="tel"
          />
        </div>
      </div>
      <div style={InputWrapper}>
        <Button
          type="submit"
          buttonStyle={ButtonStyle.mk}
          isDisabled={!(pattern.test(form.phoneNumber) && form.username !== "")}
          height="48px"
          width="100%"
        >
          <span style={{ color: "#fff" }}>{t("buttons.create_account")}</span>
        </Button>
      </div>
    </form>
  );
};

const Title: React.CSSProperties = {
  fontSize: 24,
  fontWeight: 500,
};

const FormWrapper: React.CSSProperties = {
  width: "350px",
  display: "flex",
  overflowY: "auto",
  flexDirection: "column",
  gap: 24,
};

const InputWrapper: React.CSSProperties = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 8,
};

const InnerInputWrapper: React.CSSProperties = {
  display: "grid",
  gridTemplateColumns: "130px 1fr",
  gap: 8,
};

interface PhoneCodeSelect {
  phoneCode?: CountryCode;
  codes: CountryCode[];
  onPhoneCodeSelect(code: CountryCode): void;
  showName?: boolean;
}
const PhoneCodeSelect: React.FC<PhoneCodeSelect> = ({
  phoneCode,
  codes,
  onPhoneCodeSelect,
  showName = false,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const onCodeSelectHandler = (code: CountryCode) => {
    onPhoneCodeSelect(code);
    setIsOpen(false);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !buttonRef.current?.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div style={CodeWrapper}>
      <button
        ref={buttonRef}
        onClick={toggleDropdown}
        className={stylesheet.SelectButton}
        type="button"
      >
        {phoneCode && (
          <>
            <img
              style={{ width: 32 }}
              src={phoneCode.flagUrl}
              alt={phoneCode.name}
            />
            <span>{showName ? phoneCode.name : phoneCode.dialCode}</span>
            <ArrowDownFill className={stylesheet.Icon} />
          </>
        )}
      </button>
      {isOpen && (
        <div ref={dropdownRef} className={stylesheet.SelectList}>
          {codes.map((code, index) => (
            <div
              className={stylesheet.ListItem}
              onClick={() => onCodeSelectHandler(code)}
              key={index}
            >
              <img src={code.flagUrl} alt={code.name} />
              <span>{code.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CodeWrapper: React.CSSProperties = {
  height: 40,
  boxSizing: "border-box",
  borderRadius: 4,
  border: "1px solid var(--Borde, #D5DDE0)",
  background: "var(--blanco, #FFF)",
  position: "relative",
  display: "inline-block",
};

const Header = () => {
  return (
    <div className={stylesheet.Header}>
      <img src={MKLogo} style={{ width: 73 }} alt="Fira Live / Mary Kay" />
      <div className={stylesheet.Border}></div>
    </div>
  );
};

export default SignUpExpress;
